.App {
  text-align: center;
  
 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


img{
  width: 100%;
  height: auto;
}

.ParallaxContainer {
  display: flex;
  overflow: hidden !important;
  justify-content: space-between;

  
  

  /* @media screen and (max-width: 428px) {
    display: none;
  } */
}


@keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

.react-datepicker__time-list-item--disabled {
  display: none;
}
.react-datepicker__header {
  background-color: #F8F6F2!important;

}

.react-datepicker__triangle::after {
  border-bottom-color: #F8F6F2 !important;
}

.react-datepicker__day--selected {
  background-color: #B77D38 !important;
  font-weight: bold;
}
.react-datepicker__time-list-item--selected {
  background-color: #B77D38 !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: rgba(183, 125, 56, 0.3) !important;
  color: #fcfcfc !important;;
  
}
.react-datepicker__time-container {
 box-sizing: content-box;
}

/* .react-datepicker__day--today {
background-color: none !important;
} */

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header, .react-datepicker__day-name {
  color: #8B7442 !important;
}

#svg {
  /* stroke: #8B7442; */
 
  /* stroke-dasharray: 900; */
  stroke-dasharray: 900;
  stroke-dashoffset: -900;
  animation: draw 4s forwards 1;
  animation-delay: 0.5s;
  width: 15vw;
  height: auto;

  min-width: 200px;


}

@keyframes draw {
  75% {
    stroke-dashoffset: 0;
    fill: transparent;
    stroke: #8B7442;
    stroke-width: 1px;
    
  }

  /* 50% {
    stroke-width: 0;
   
 
  } */
  100% {
    stroke-dashoffset: 0;
    stroke-width: 0;
    fill: #8B7442;
    
  }
}

.fc .fc-button-primary {
  background-color: #B77D38 !important;
  border-color: #B77D38 !important;
}



::-webkit-input-placeholder { /* Chrome */
  color: #999;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #999;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #999;
  opacity: 1;
}


.banner-slide {
  height: 100%;
  width: 100%;
}

.swiper-slide {
  align-self: stretch;
}