@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Vidaloka&display=swap');

body {
  margin: 0;
  overflow-x: hidden;
  font-family: 'Noto Sans TC', 'Montserrat', 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

p {
  text-align: justify;
}




.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color:#000;
  opacity: 1;
  background: rgba(0,0,0,0.2);
  
  
}

.swiper-pagination-bullet-active {
  color:#fff;
  background: #ffb71b !important;
}


.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  will-change: opacity;
  border: 1px solid yellow;
}

@keyframes wave {
  0% {
      transform: translateX(0);
  }
  50% {
      transform: translateX(-25%);
  }
  100% {
      transform: translateX(-50%);
  }
}




a {
 text-decoration: none;
 
}

